// ~ COLORS ~ //
$white: #fff;
$gray: #29262e;
$lightGray: #8a8298;
$lightGray2: #8c849a;
$lighterGray: #999999;
$lightText: #bfbfbf;
$grayHover: #595959;
$bg: #16141a;
$blue: #00a1ff;
$darkBlue: #0071b3;
$purple: #997ae0;
$darkPurple: #572bba;
$pink: #ac0d85;
$yellow: #b1e02f;

// ~ VALUES ~ //
// TEXT
$text_small: 11px;
$text_medium: 14px;
$text_large: 1.75rem;
$text_submit: 16px;
$text_heading: 36px;
$text_subheading: 18px;

// MARGIN
$margin_medium: 25px;

// PADDING
$padding_medium: 20px;

// HEIGHT
$height_small: 15px;
$height_medium: 24px;
$height_large: 50px;
$height_input: 2.5rem;

// BORDER-RADIUS
$borderRadius_small: 15px;
$borderRadius_medium: 25px;
