* {
  box-sizing: border-box;
}

body,
html,
#root {
  font-family: 'Geomanist';
  height: 100%;
}

a {
  text-decoration: none;
}
#dropdown-button-dark-example1{
  background-color: transparent;
  border: transparent;
  font-family: 'Geomanist-Medium';
  text-transform: uppercase;
  font-size: 17px;
  outline: none;
  box-shadow: none;

}
.dropdown-menu{
  background-color: #29262e !important;
width: 14.5rem;
border-radius: 0 !important;
border:none;

}
.dropdown-menu[data-bs-popper] {
  top: 80%;
left:-8px}
.dropdown-item {
  background-color: transparent !important;
  font-size: 18px !important;
  padding:  0.713rem 1.7rem;
  font-family: 'Geomanist-Medium';
  text-transform: uppercase;
  color: White !important;
  line-height: 22.12px;
white-space: pre-wrap !important;
  
}
.dropdown-item:last-child{
  padding-bottom: 0.25rem;

} 


// .dropdown-item:hover{
//   color: #00a1ff !important;
//     opacity: 0.75;
//   // transition: 0.5s all ease;
//  }

.header_drop{
  width: 11.5px;
  margin-left: 6px;
  div{
    margin-top: -2px;
  }
svg{
  path{
    fill: #ffffff !important;
   
  }
 
}
 

}
#dropdown-button-dark-example1{
  display: flex;
  svg{
    path{
      fill: #ffffff !important;
      transition: 0.5s all ease;
    }
   
  }
}
#dropdown-button-dark-example1:hover{
  svg{
    path{
      fill: #00a1ff !important;
    opacity: 0.75;
  transition: 0.5s all ease;
     
    }
 
 }
}
i{
  color: white;
  
}
.discord_icon{
  width: 1.2rem;
}
.nav-link-item{
  padding: 13px 20px !important;
  font-size: 18px !important;
}