@import "src/common/styles/variables.scss";

.txt {
  // COLOR
  &-white {
    color: $white;
  }

  &-gray {
    color: $gray !important;
  }

  &-lightgray {
    color: $lightGray;
  }

  &-blue {
    color: $blue !important;
  }

  &-purple {
    color: $purple;
  }

  &-pink {
    color: $pink;
  }

  // SIZE
  &-small {
    font-size: $text_small;
  }

  &-medium {
    font-size: $text_medium;
  }

  &-large {
    font-size: $text_large;
  }

  &-submit {
    font-size: $text_submit;
  }

  &-heading {
    font-size: $text_heading;
  }

  &-subheading {
    font-size: $text_subheading;
  }
  &-three-lines {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
}

.motoclub-logo {
  // transform: scale(1.5);
  width: 250px;
}

.heading {
  font-family: 'Geomanist-Bold';

}

.bg {
  &Image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  &dark {
    background-color: $bg;
  }

  &gray {
    background-color: $gray;
  }

  &white {
    background-color: $white !important;
  }

  &yellow {
    background-color: $yellow;
  }

  &pink {
    background-color: $pink;
  }

  &blue {
    background-color: $blue !important;
  }
}

.border {
  &-gray {
    border: 1.5px solid $gray;
  }
}
.pack_container {
  position: relative;
  display: flex;
  h1 {
    position: absolute;
    bottom: 20px;
    left: 45px;
    font-size: 1.5rem;
    color: white;
    font-weight: 600;
    @media (max-width: 767px) {
      left: 27px !important;
      font-size: 1rem !important;
    }
  }
}
.pack_cards {
  padding: 0px 8px;
  img {
    width: -webkit-fill-available;
  }
}
.nav {
  //navbar
  &bar {
    background-color: $gray;
    color: $white;
    padding: $padding_medium;
    min-height: 100px;

    &-brand {
      width: 225.44px;
      max-width: 292px;
    }

    &-link {
      cursor: pointer;
      color: $white;
      padding: 13px 10px;
      transition: 0.5s all ease;
      font-family: 'Geomanist-Medium';
      font-size: 17px;
      font-weight: 500;
      // text-transform: uppercase;
      line-height: 27px;
      &.active {
        color: $blue;
      }

      &:hover {
        color: $blue;
        opacity: 0.75;
      }
    }

    &-link-ft {
      cursor: pointer;
      color: $white;
      padding: 13px 10px;
      transition: 0.5s all ease;
      font-size: 14px;
      &.active {
        color: $blue;
      }

      &:hover {
        color: $blue;
        opacity: 0.75;
      }
    }
  }

  &-link {
    color: $white;
    border: 0 !important;
    padding-left: 0 !important;

    &:hover {
      color: $white;
    }

    &.active {
      color: $blue !important;
      background-color: transparent !important;
    }
  }
}

.hideMobile {
  display: none;

  @media (min-width: 767px) {
    display: flex;
    flex-direction: row;
  }
}

#fw-bold{
  font-family: 'Geomanist-Medium';

}
.dropdown {
  &-menu {
    background-color: $lightGray;
    border-radius: $borderRadius_small;
  }

  &-item {
    font-size: $text_small;
    color: $white;

    &:hover {
      background-color: $lightGray;
      color: $lightText;
      border-radius: $borderRadius_small;
    }
  }

  &-toggle::after {
    display: none;
  }
}

.textfield-1 {
  border: 2px solid $lightGray;
  border-radius: $borderRadius_medium;
  padding: 0.75rem 1rem;

  &.is-invalid {
    border-color: red;
  }
}

.formInput {
  color: $white;
  width: 100%;
  height: $height_input;
  background-color: $gray;
  outline: none;
  border: none;
  border-radius: $borderRadius_medium;
  transition: 0.5s all ease;

  &:focus {
    background-color: $gray;
    color: $lightText;
    box-shadow: 0 0 0 0.1rem $blue;
  }

  &-border {
    box-shadow: 0 0 0 0.1rem $lightGray;
  }
}

.formField {
  color: $lightGray;
  background-color: $gray;
  border: none;

  &:focus {
    background-color: $gray;
    color: $lightText;
    box-shadow: none;
  }
}

.inputActive {
  box-shadow: 0 0 0 0.1rem $blue;
}

.showPass {
  color: $lightGray;
  border: none;
  cursor: pointer;

  &:focus,
  &:active {
    box-shadow: none !important;
    outline: none;
  }
}

.formButton {
  color: $white;

  &:hover {
    background-color: $grayHover;
  }
}

.formSubmit {
  font-weight: 600;
  background-color: $blue;

  &:hover {
    background-color: $darkBlue;
  }
}
.justify-content-center {
  justify-content: center;
}
.coinbase-button {
  height: 0;
  visibility: hidden;
}
.form-check {
  display: flex;
  align-items: center;

  &-input {
    width: $height_medium;
    height: $height_medium;
    background-color: $gray;
    margin-top: 0;

    &:focus {
      box-shadow: 0 0 0 0.25rem rgba($color: $blue, $alpha: 0.25);
      border: none;
    }

    &:checked {
      background-color: $blue;
      border-color: $blue;
    }
  }

  &-label {
    font-size: $text_small;
    margin-left: 0.5rem;
  }
}

.redirectBlue {
  font-weight: 600;
  color: $blue;
  transition: 0.5s all ease;

  &:hover {
    color: $darkBlue;
  }
}

.redirectWhite {
  font-weight: 600;
  color: $white;

  &:hover {
    color: $white;
  }
}

.termsModal .modal-dialog {
  margin: 0;
  top: 35%;
}

.modal {
  &-content {
    background-color: transparent;
    border: none;
  }
}

.socials {
  transition: 0.5s all ease;
  opacity: 1;

  &:hover {
    opacity: 0.75;
  }
}

.packImg {
  // width: 18rem;
  -webkit-filter: drop-shadow(25px 25px 25px #00000080);
  filter: drop-shadow(25px 25px 25px #00000080);
  transition-duration: 0.3s;
  transition-property: transform;
}

.packImg:hover {
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.responsive {
  &-left {
    text-align: center;

    @media (min-width: 576px) {
      text-align: start;
    }
  }

  &-right {
    text-align: center;

    @media (min-width: 576px) {
      text-align: end;
    }
  }

  &-end {
    justify-content: center;

    @media (min-width: 576px) {
      justify-content: flex-end;
    }
  }

  &-start {
    width: fit-content;
    justify-content: center;
    margin-bottom: $margin_medium;

    @media (min-width: 576px) {
      justify-content: flex-start;
      margin-bottom: 0;
    }
  }

  &-hide {
    display: none;

    @media (min-width: 576px) {
      display: flex;
      justify-content: flex-end;
    }
  }

  &-show-nav {
    display: none;

    @media (max-width: 767px) {
      display: flex;
    }
  }

  &-show {
    display: flex;

    @media (min-width: 576px) {
      display: none;
    }
  }

  &-mt-4 {
    margin-top: 0;

    @media (max-width: 767px) {
      margin-top: 1.5rem;
    }
  }
}

.svg {
  &-pack {
    &-white path {
      fill: $white;
    }

    &-gray path {
      fill: $gray;
    }
  }

  &-tab {
    &-white path {
      fill: $white;
    }

    &-blue path {
      fill: $blue;
    }
  }
}

.mb1 {
  &-tab {
    @media (max-width: 991px) {
      margin-bottom: 1rem;
    }
  }

  &-mobile {
    @media (max-width: 767px) {
      margin-bottom: 1rem;
    }
  }

  &-small {
    @media (max-width: 575px) {
      margin-bottom: 1rem;
    }
  }
}

// HIDE SPINNER ON INPUT NUMBER
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
  /* Firefox */
}

.pack-animation {
  .modal-body {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    padding: 0;

    video {
      height: auto;
      width: 100%;
    }
  }
}

.sparknft {
  .card-img-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(10px);
    transition: all 0.2s linear 0s;

    img {
      border-radius: 12px;
      max-width: 100%;
      max-height: 100%;
      object-fit: cover;
    }
  }

  a {
    color: $blue;

    &:hover,
    &:active {
      color: $blue;
    }
  }
}

.share {
  height: 50px;
  padding: 0 25px;
}

.custum-tabs {
  border-top: 1px solid rgba(138, 130, 152, 0.5);
  border-bottom: 1px solid rgba(138, 130, 152, 0.5);
}

.filters {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.Filter h1 {
  color: #d6d2bc;
}
.btns button {
  color: #fff;
  background-color: #29262e;
  outline: none;
  border: none;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  transition: 0.5s all ease;
  margin-right: 1rem;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.btns button {
  img {
    width: 1rem;
  }
}
.btns button:hover {
  background-color: $darkBlue !important;
  color: white;
}

.Filter h1 {
  color: white;
  font-size: 1.5rem;
}
