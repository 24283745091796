@import 'src/common/styles/variables.scss';

.customCard {
  color: $white;
  border-radius: $borderRadius_small;
  margin-bottom: $margin_medium;
  padding: $margin_medium;
  &-footerTxt {
    font-size: $text_medium;
  }
}
