@import 'src/common/styles/variables.scss';

.login {
  &-txtBtn {
    font-size: $text_medium;
    color: $lightGray2;
    cursor: pointer;
    transition: 0.5s all ease;
    &:hover {
      color: $blue;
    }
  }
}
