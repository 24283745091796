@import 'src/common/styles/variables.scss';

.formSeparator {
  border-bottom: 1px solid;
  width: 70%;
  height: $height_small;
  text-align: center;
  margin-top: $margin_medium;
  margin-bottom: $margin_medium;
  & > span {
    color: $white;
  }
}
.color {
  &-gray {
    color: $gray;
  }
  &-dark {
    color: $bg;
  }
}
.bg {
  &gray {
    background-color: $gray;
  }
  &dark {
    background-color: $bg;
  }
}
